<template>
    <div :class="['filter-sheet', { isOpen: locationFilterSheetOpen }]">
        <div class="filter-sheet-overlay"></div>
        <div class="location-filter-sheet">
            <div class="body">
                <h4>Filter by Stage</h4>
                <div class="filter-options">
                    <div :class="['filter-option', { isActive: filters.location === ''}]" 
                    @click="$store.commit('artists/setLocationFilter', '')"
                    @touchend.prevent="$store.commit('artists/setLocationFilter', '')"
                    >
                        All
                    </div>
                    <div :class="['filter-option', { isActive: filters.location === location}]" 
                    v-for="location in locationsFilterList"
                    @click="$store.commit('artists/setLocationFilter', location)"
                    @touchend.prevent="$store.commit('artists/setLocationFilter', location)"
                    >
                        {{ location }}
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="button green" @click="$store.commit('app/toggleLocationFilterSheetOpen')" @touchend.prevent="$store.commit('app/toggleLocationFilterSheetOpen')">
                    Close
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'LocationFilterSheet',
    data() {
        return {
            htmlRef: null
        }
    },
    computed: {
        ...mapState('app', ['locationFilterSheetOpen']),
        ...mapState('artists', ['filters', 'locationsFilterList'])
    },
    mounted() {
        let html = document.querySelector('html');
        this.htmlRef = html;
    },
    watch: {
        locationFilterSheetOpen(val) {
            val ? this.htmlRef.classList.add('scroll-lock') : this.htmlRef.classList.remove('scroll-lock') 
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.isOpen {
    .filter-sheet-overlay {
        opacity: 1;
        visibility: visible;
    }
    .location-filter-sheet {
        transform: translateY(0%);
    }
}

.filter-sheet-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.6);
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
}
.location-filter-sheet {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 37vh;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 5px 5px 0 0;
    transition: all 0.3s;
    transform: translateY(100%);
}
.actions {
    // height: $tab-bar-height + 5;
    background: #f0f0f0;
    margin-top: auto;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.button {
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    display: inline-flex;
    background: #fff;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,0.12);
    border-bottom: 2px solid rgba(0,0,0,0.12);
    font-size: 14px;
    min-width: 150px;

    &.green {
        background: $green;
        color: #fff;
    }
}

.body {
    padding: 20px;
    h1,h2,h3,h4 {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
    }
}

.filter-options {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
}

.filter-option {
    padding: 6px 15px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 5px;
    font-size: 14px;
    opacity: 0.5;

    &.isActive {
        opacity: 1;
        color: $green;
        border-color: $green;
    }
}
</style>