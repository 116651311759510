<template>
  <div>
    <div class="header-wrap">
      <TitleBar :title="pageTitle" :count="favouritedArtists.length"/>
    </div>
    <div class="page-wrapper">
      <div class="empty-page" v-if="isEmpty(favouritedArtists)">
        <div>
          <FrownIcon class="empty-icon"/>
          <h2>No Schedule</h2>
          <p>Looks like you haven't added any acts yet, go to <router-link to="/">discover</router-link> to add some</p>
        </div>
      </div>
      <div class="w-100" v-else>
        <div class="artist-list">
          <ArtistPod v-for="artist in favouritedArtists" :artist="artist" :key="artist.name"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import { isEmpty } from 'lodash';
import ArtistList from '@/components/ArtistList';
import ArtistPod from '@/components/ArtistPod';
import FilterBar from '@/components/FilterBar';
import TitleBar from '@/components/TitleBar';
import { parse } from 'date-fns';
import { FrownIcon } from 'vue-feather-icons';

export default {
  props: ['pageTitle'],
  components: {ArtistPod, FilterBar, TitleBar, FrownIcon },
  head: function() {
    return {
      title: {
        inner: 'Schedule'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} User Schedule`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('artists', ['artists']),
    ...mapState('user', ['favourites']),
    favouritedArtists() {
      if (this.isEmpty(this.artists)) {
        return [];
      }
      return this.favourites.map(name => {
        return this.artists[name.toUpperCase()];
      }).sort((a,b) => {
        return parse(a.start) - parse(b.start)
      })
    }
  },
  methods: {
    isEmpty(arg) {
      return isEmpty(arg);
    }
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';

a {
  color: $green;
}

.w-100 {
  width: 100%;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  padding: 0 20px;
}

.empty-page {
  margin: 0 auto 30px auto;
  text-align: center;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1,h2 {
    margin: 0 0 .5rem 0;
    padding: 0 0 0 0;
  }

  p {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    line-height: 1.5;
  }
}

.empty-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 15px;
}

.artist-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}

.isPast {
  opacity: 0.7;
}

@media screen and (max-width: 420px) {
  .page-wrapper {
    max-width: 100%;
  }
}
</style>
