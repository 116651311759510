<template>
  <div>
    <div class="header-wrap">
      <TitleBar :title="pageTitle"/>
      <FilterBar v-on:is-fixed="handleFixed"/>
    </div>
    <div ref="pageWrapper" class="page-wrapper">
      <div v-if="isEmpty(artists)">Loading...</div>
      <div v-else>
        <ArtistList/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import { isEmpty } from 'lodash';
import ArtistList from '@/components/ArtistList';
import FilterBar from '@/components/FilterBar';
import TitleBar from '@/components/TitleBar';

export default {
  props: ['pageTitle'],
  components: {ArtistList, FilterBar, TitleBar},
  head: function() {
    return {
      title: {
        inner: 'Line Up'
      },
      meta: [
        {
          name: 'description',
          content: `Discover ${this.appTitle} Lineup`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('artists', ['artists', 'filters'])
  },
  methods: {
    isEmpty(arg) {
      return isEmpty(arg);
    },
    handleSwipe(e) {
      let { day } = this.filters;
      console.log(e);
      // swipe left
      if (e.type === 'swipeleft' && (day === 'Thursday' || day === 'Friday')) {
        if (day === 'Thursday') {
          this.$store.commit('artists/setDayFilter', 'Friday');
        } else {
          this.$store.commit('artists/setDayFilter', 'Saturday');
        }
      }
      
      // swipe right
      if (e.type === 'swiperight' && (day === 'Saturday' || day === 'Friday')) {
        if (day === 'Saturday') {
          this.$store.commit('artists/setDayFilter', 'Friday');
        } else {
          this.$store.commit('artists/setDayFilter', 'Thursday');
        }
      }
    },
    handleFixed({ isFixed, height }) {
      if (isFixed) {
        this.$refs.pageWrapper.style.paddingTop = `${height}px`
      } else {
        this.$refs.pageWrapper.style.paddingTop = `0`
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  padding: 0 20px;
}

.page-title {
  margin: 0 auto 30px auto;
  text-align: center;
  h1,h2 {
    margin: 0 0 .5rem 0;
    padding: 0 0 0 0;
  }

  p {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
}

@media screen and (max-width: 420px) {
  .page-wrapper {
    max-width: 100%;
  }
}
</style>
