export default {
    ADD_FAVOURITE: (state, payload) => {
        if (state.favourites.indexOf(payload) === -1) {
            state.favourites.push(payload);
        }
    },
    REMOVE_FAVOURITE: (state, payload) => {
        let idx = state.favourites.indexOf(payload);
        if (idx !== -1) {
            state.favourites.splice(idx, 1);
        }
    },
    SET_FAVOURITES: (state, payload) => {
        state.favourites = payload;
    }
}