import db from '@/db';

export default {
    ADD_FAVOURITE_TO_DB: async ({ commit }, payload) => {
        await db.schedule.put({name: payload});
        commit('ADD_FAVOURITE', payload);
    },
    REMOVE_FAVOURITE_FROM_DB: async ({ commit }, payload) => {
        await db.schedule.delete(payload);
        commit('REMOVE_FAVOURITE', payload);
    }
}