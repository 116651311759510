import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import UserSchedule from '@/views/UserSchedule';
import Info from '@/views/Info';
import Now from '@/views/Now';
import { isNil } from 'lodash'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: {
        pageTitle: 'Discover'
      }
    },
    {
      path: '/schedule',
      name: 'Your Schedule',
      component: UserSchedule,
      props: {
        pageTitle: 'Your Schedule'
      }
    },
    {
      path: '/info',
      name: 'Info',
      component: Info,
      props: {
        pageTitle: 'Info'
      }
    },
    {
      path: '/now',
      name: 'now',
      component: Now,
      props: {
        pageTitle: 'Now + Next'
      }
    },
    { path: '*', redirect: '/' }
  ]
})

/**
 * Handle user redirections
 */
// router.beforeEach((to, from, next) => {
//   if (
//     !(to.meta && to.meta.authNotRequired) &&
//     isNil(store.state.authentication.user)
//   ) {
//     const path =
//       store.state.authentication.user === null ? '/login' : '/check-login'
//     return next(`${path}?redirectUrl=${to.path}`)
//   }
//   next()
// })

export default router
