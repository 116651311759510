<template>
    <div :class="['artist-pod', { isPast: isPast}]" v-hammer:tap="handleTap">
        <div class="header-image">
            <img crossorigin :src="artist.headerImage" :alt="artist.name">
            <div class="title-overlay">
                <div class="label pill-label" v-show="isPast">Past</div>
                <div class="label pill-label live" v-show="isLive">Live</div>
                <p class="artist-title">{{ artist.name }}</p>
                <div class="heart-icon" :class="{ isFavourited }" @click="toggleFavourite" @touchend.prevent="toggleFavourite">
                    <HeartIcon/>
                </div>
            </div>
        </div>
        <div class="artist-body">
            <div>
                <span class="label">{{ format(artist.start, 'dddd') }}</span>
                <div>{{ format(artist.start, 'HH:mm') }} - {{ format(artist.end, 'HH:mm') }}</div>
            </div>
            <div class="text-right">
                <span class="label">Stage</span>
                <div>{{ artist.location }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isWithinRange from 'date-fns/is_within_range'
import { HeartIcon } from 'vue-feather-icons';
import { mapState } from 'vuex';
export default {
    components: { HeartIcon },
    props: ['artist'],
    computed: {
        ...mapState('user', ['favourites']),
        isFavourited() {
            return this.favourites.indexOf(this.artist.id) !== -1;
        },
        isPast() {
            let now = new Date();
            return now > this.parse(this.artist.end);
        },
        isLive() {
            let now = new Date();
            return this.isWithinRange(now, this.parse(this.artist.start),this.parse(this.artist.end));
        }
    },
    methods: {
        format,
        parse,
        isWithinRange,
        toggleFavourite() {
            if (!this.isFavourited) {
                this.$store.dispatch('user/ADD_FAVOURITE_TO_DB', this.artist.id);
            } else {
                this.$store.dispatch('user/REMOVE_FAVOURITE_FROM_DB', this.artist.id);
            }
        },
        handleTap(e) {
            e.preventDefault();
            if (e.tapCount === 2) {
                this.toggleFavourite();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.artist-pod {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 12px 10px -10px rgba(0,0,0,0.12);

    // &.isPast {
    //     opacity: 1;
    // }
}
.header-image {
    height: 120px;
    width: 100%;
    background-size: cover;
    background-position: 50% 0%;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 110%;
        transform: translate(-50%,-50%);
    }
}
.title-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
}
img {
    max-width: 100%;
}

.artist-title {
    padding: 0 15px;
    text-align: center;
}

.artist-body {
    padding: 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0,0,0,0.12);
    border-top: none;
    border-radius: 0 0 5px 5px;

    p {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
    }
}

.label {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #999;
    font-size: 12px;
}

.pill-label {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 5px;
    background: #666;
    color: #fff;
    border-radius: 3px;
    font-size: 10px;

    &.live {
        background: #FF5756;
    }
}

.heart-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.25s;

    &.isFavourited {
        opacity: 1;
        color: red;
    }
}

.text-right {
    text-align: right;
}
</style>


