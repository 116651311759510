<template>
    <div ref="filterBar" :class="['filter-bar-wrap', { isFixed: isFixed }]">
        <div class="filter-bar">
            <div class="day-filters">
                <div 
                v-for="item in days"
                :class="['filter-item', { isActive: filters.day === item.value}]" 
                @click="handleFilter(item.value)"
                @touchend.prevent="handleFilter(item.value)"
                >
                    {{ item.label }}
                </div>
            </div>
            <div class="stage-filter" @click="openLocationFilter" @touchend.prevent="openLocationFilter">
                <MapPinIcon/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { MapPinIcon } from 'vue-feather-icons';
export default {
    components: { MapPinIcon },
    name: 'FilterBar',
    data() {
        return {
            days: [
                {
                    label: 'Thu',
                    value: 'Thursday'
                },
                {
                    label: 'Fri',
                    value: 'Friday'
                },
                {
                    label: 'Sat',
                    value: 'Saturday'
                }
            ],
            isFixed: false,
            offsetTop: 0
        }
    },
    computed: {
        ...mapState('artists', ['filters'])
    },
    methods: {
        handleFilter(day) {
            this.$store.commit('artists/setDayFilter', day);
        },
        openLocationFilter() {
            this.$store.commit('app/toggleLocationFilterSheetOpen');
        },
        handleScroll(e) {
            if (window.scrollY >= this.offsetTop) {
                this.isFixed = true;
                this.$emit('is-fixed', {
                    isFixed: true,
                    height: this.$refs.filterBar.clientHeight
                });
            } else {
                this.isFixed = false;
                this.$emit('is-fixed', {
                    isFixed: false,
                    height: this.$refs.filterBar.clientHeight
                });
            }
        }
    },
    mounted() {
        document.addEventListener('scroll', this.handleScroll);
        this.offsetTop = this.$refs.filterBar.offsetTop
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style lang="scss" scoped>
.filter-bar-wrap {
    padding: 7px 20px 0 20px;
    height: 51px;

    &.isFixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 15;
    }
}

.filter-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 3px solid rgba(0,0,0,0.08);
    margin-bottom: 20px;
}

.stage-filter {
    margin-left: auto;
}

.filter-item {
    position: relative;
    top: 3px;
    left: 0;
    padding: 8px 15px;
    margin: 0 0;
    opacity: 0.5;
    border-bottom: 3px solid transparent;

    &.isActive {
        // background: #4a8b83;
        // color: #fff;
        color: #4a8b83;
        opacity: 1;
        font-weight: 500;
        border-color: #4a8b83;
    }
}

.day-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>

