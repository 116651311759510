export default {
    setArtists: (state, payload) => {
        state.artists = payload;
    },
    setDayFilter: (state, payload) => {
        state.filters.day = payload;
    },
    setLocationFilter: (state, payload) => {
        state.filters.location = payload;
    },
    setLocationsFilterList: (state, payload) => {
        state.locationsFilterList = payload;
    }
}