<template>
    <div class="tab-bar">
        <router-link to="/schedule">
            <div :class="['tab-item', { isCurrent: $route.path === '/schedule'}]">
                <HeartIcon/>
                <small>Schedule</small>
            </div>
        </router-link>
        <router-link to="/">
            <div :class="['tab-item', { isCurrent: $route.path === '/'}]">
                <SearchIcon/>
                <small>Discover</small>
            </div>
        </router-link>
        <router-link to="/now">
            <div :class="['tab-item', { isCurrent: $route.path === '/now'}]">
                <ZapIcon/>
                <small>Now</small>
            </div>
        </router-link>
        <router-link to="/info">
            <div :class="['tab-item', { isCurrent: $route.path === '/info'}]">
                <InfoIcon/>
                <small>Info</small>
            </div>
        </router-link>
    </div>
</template>

<script>
import { HomeIcon, SearchIcon, HeartIcon, InfoIcon, ZapIcon } from 'vue-feather-icons';
export default {
    props: [],
    components: { HomeIcon, SearchIcon, HeartIcon, InfoIcon, ZapIcon },
    name: 'TabBar',
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.tab-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $tab-bar-height;
    width: 100%;
    background: #fff;
    border-top: 2px solid rgba(0,0,0,0.1);
}

.tab-item {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    color: #333;
    opacity: 0.3;

    small {
        display: block;
        font-size: 11px;
        margin-top: 3px;
    }

    &.isCurrent {
        opacity: 1;
        color: $green;
    }
}
</style>

