<template>
    <div class="artist-list">
        <ArtistPod v-for="artist in filteredArtists" :artist="artist" :key="artist.name"/>
    </div>
</template>

<script>
import ArtistPod from '@/components/ArtistPod';
import { mapState } from 'vuex';
import { parse, format } from 'date-fns';
export default {
    components: {ArtistPod},
    name: 'ArtistList',
    computed: {
        ...mapState('artists',['artists', 'filters']),
        filteredArtists() {
            let artists = Object.values(this.artists);
            let filteredByDay = artists.filter(artist => {
                let day = format(artist.start, 'dddd');
                return day === this.filters.day;
            }).sort((a,b) => {
                return parse(a.start) - parse(b.start);
            })
            if (this.filters.location !== '') {
                return filteredByDay.filter(artist => artist.location === this.filters.location)
            } else {
                return filteredByDay;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.artist-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>


