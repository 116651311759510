<template>
  <div>
    <div class="header-wrap">
      <TitleBar :title="pageTitle"/>
    </div>
    <div class="page-wrapper">
      <div class="w-100">
        <p class="subheading">Arc</p>
        <div class="artist-list">
          <ArtistPod v-for="artist in arcEvents" :artist="artist" :key="artist.name"/>
        </div>
        <p class="subheading">Yohkai</p>
        <div class="artist-list">
          <ArtistPod v-for="artist in yohkaiEvents" :artist="artist" :key="artist.name"/>
        </div>
        <p class="subheading">Bixler</p>
        <div class="artist-list">
          <ArtistPod v-for="artist in bixlerEvents" :artist="artist" :key="artist.name"/>
        </div>
        <p class="subheading">PX3</p>
        <div class="artist-list">
          <ArtistPod v-for="artist in px3Events" :artist="artist" :key="artist.name"/>
        </div>
        <p class="subheading">Bar Room</p>
        <div class="artist-list">
          <ArtistPod v-for="artist in barRoomEvents" :artist="artist" :key="artist.name"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import { isEmpty } from 'lodash';
import TitleBar from '@/components/TitleBar';
import ArtistList from '@/components/ArtistList';
import ArtistPod from '@/components/ArtistPod';
import parse from 'date-fns/parse';
import isWithinRange from 'date-fns/is_within_range';
import closestIndexTo from 'date-fns/closest_index_to';

export default {
  props: ['pageTitle'],
  components: {TitleBar, ArtistPod},
  head: function() {
    return {
      title: {
        inner: 'Now + Next'
      },
      meta: [
        {
          name: 'description',
          content: `What's on Now at Arctangent 2019`,
          id: 'desc'
        }
      ]
    }
  },
  data() {
      return {
          now: new Date()
      }
  },
  computed: {
      ...mapState('artists', ['artists']),
      arcEvents() {
          if (this.isEmpty(this.artists)) {
            return [];
        }
        let now = this.now;
        let nextIndex = null;
        let artists = Object.values(this.artists);
        let filteredByLocation = artists.filter(artist => {
            return artist.location === 'Arc';
        }).sort((a,b) => {
            return parse(a.start) - parse(b.start);
        })

        const dates = filteredByLocation.map(artist => {
            return this.parse(artist.start);
        })

        let closest = this.closestIndexTo(now, dates);
        let next = closest !== filteredByLocation.length ? filteredByLocation[closest+1] : [];
        return [filteredByLocation[closest], ...[next]];
      },
      yohkaiEvents() {
          if (this.isEmpty(this.artists)) {
            return [];
        }
        let now = this.now;
        let nextIndex = null;
        let artists = Object.values(this.artists);
        let filteredByLocation = artists.filter(artist => {
            return artist.location === 'Yohkai';
        }).sort((a,b) => {
            return parse(a.start) - parse(b.start);
        })
        
        const dates = filteredByLocation.map(artist => {
            return this.parse(artist.start);
        })

        let closest = this.closestIndexTo(now, dates);
        let next = closest !== filteredByLocation.length ? filteredByLocation[closest+1] : [];
        return [filteredByLocation[closest], ...[next]];
      },
      bixlerEvents() {
          if (this.isEmpty(this.artists)) {
            return [];
        }
        let now = this.now;
        let nextIndex = null;
        let artists = Object.values(this.artists);
        let filteredByLocation = artists.filter(artist => {
            return artist.location === 'Bixler';
        }).sort((a,b) => {
            return parse(a.start) - parse(b.start);
        })
        
        const dates = filteredByLocation.map(artist => {
            return this.parse(artist.start);
        })

        let closest = this.closestIndexTo(now, dates);
        let next = closest !== filteredByLocation.length ? filteredByLocation[closest+1] : [];
        return [filteredByLocation[closest], ...[next]];
      },
      px3Events() {
          if (this.isEmpty(this.artists)) {
            return [];
        }
        let now = this.now;
        let nextIndex = null;
        let artists = Object.values(this.artists);
        let filteredByLocation = artists.filter(artist => {
            return artist.location === 'PX3';
        }).sort((a,b) => {
            return parse(a.start) - parse(b.start);
        })
        
        const dates = filteredByLocation.map(artist => {
            return this.parse(artist.start);
        })

        let closest = this.closestIndexTo(now, dates);
        let next = closest !== filteredByLocation.length ? filteredByLocation[closest+1] : [];
        return [filteredByLocation[closest], ...[next]];
      },
      barRoomEvents() {
          if (this.isEmpty(this.artists)) {
            return [];
        }
        let now = this.now;
        let nextIndex = null;
        let artists = Object.values(this.artists);
        let filteredByLocation = artists.filter(artist => {
            return artist.location === 'Bar Room';
        }).sort((a,b) => {
            return parse(a.start) - parse(b.start);
        })
        
        const dates = filteredByLocation.map(artist => {
            return this.parse(artist.start);
        })

        let closest = this.closestIndexTo(now, dates);
        let next = closest !== filteredByLocation.length ? filteredByLocation[closest+1] : [];
        return [filteredByLocation[closest], ...[next]];
      },
  },
  methods: {
    parse,
    isWithinRange,
    closestIndexTo,
    isEmpty(arg) {
      return isEmpty(arg);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

a {
  color: $green;
}

.w-100 {
  width: 100%;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  padding: 0 20px;
}

.content {
    font-size: 16px;
    line-height: 1.5;

    p {
        margin: 0 0 12px 0;
        padding: 0 0 0 0;
    }

    h1,h2,h3 {
        padding: 0 0 0 0;
        margin: 1.5rem 0 12px 0;
    }
    .byline {
        margin-top: 1.5rem;
        padding: 10px;
        background: #FCEAA8;
    }
}

.artist-list {
    padding-top: 0;
}

.subheading {
    padding: 1rem 0 0 0;
    margin: 0 0 .5rem 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}


@media screen and (max-width: 420px) {
  .page-wrapper {
    max-width: 100%;
  }
}
</style>
