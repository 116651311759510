import state from './artists.state'
import mutations from './artists.mutations'
import actions from './artists.actions'
import getters from './artists.getters'

export default {
  namespaced: true,
  state,
  mutations,
  // actions,
  // getters
}