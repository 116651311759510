<template>
  <div>
    <div class="header-wrap">
      <TitleBar :title="pageTitle"/>
    </div>
    <div class="page-wrapper">
      <div class="content">
          <h3>About the festival</h3>
          <p>Looking for info about arctangent, <a rel="noopener" target="_blank" href="https://arctangent.co.uk/info/">click here</a>.</p>
          <h3>What is this?</h3>
          <p>This is a quick web app I've put together so I can save and keep track of all the bands I want to see at Arctangent 2019.</p>
          <p>You can easily add it to the home screen of your phone. It will save everything onto your phone so that you can use it even when you're offline. It should feel and work like a real app.</p>
          <h3>How to use</h3>
          <p>You can use this app to favourite your artists / bands from the discover section of the app. You can do this by tapping the heart icon, or double tap anywhere on a band / artist.</p>
          <p>You can filter by the day they're playing and by location.</p>
          <p>Once you've favourited someone they'll appear in your schedule / favourites allowing you to easily see what time, and where they're playing.</p>
          <h3>Where is everything from?</h3>
          <p>All the images I've used have been taken from the <a rel="noopener" target="_blank" href="https://arctangent.co.uk">arctangent website</a>. All rights go to arctangent themselves and I am by no means the creator of that content. I am by no means affliated or associated with arctangent and have made this app to scratch my own itch.</p>
          <p>The times and stage locations are all taken from <a rel="noopener" target="_blank" href="https://clashfinder.com">clashfinder</a>, a great service that arctangent(and many other festivals) are using. I wouldn't have even been able to make this if it wasn't for clashfinder so shout out to them.</p>
          <p>The design of the app, code, features and putting it together was done by myself.</p>
          <h3>Does this take up space?</h3>
          <p>Yes, once everything is offline it will take up a little bit of space on your phone. That's mainly because of all the images. I worked out it should take no more than 5MB</p>
          <h3>Why make this?</h3>
          <p>I wanted to scratch my own itch, also I build stuff for the web full time and this was a bunch of stuff I haven't had chance to play wth yet. This seemed like a great reason</p>
          <p>If you have any thoughts please reach out on <a rel="noopener" target="_blank" href="https://twitter.com/birdyboy18">twitter</a></p>

          <p class="byline">Made with love and excitement by <a rel="noopener" target="_blank" href="https://twitter.com/birdyboy18">Paul Bird</a>. See you at ATG</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import { isEmpty } from 'lodash';
import TitleBar from '@/components/TitleBar';
import { parse } from 'date-fns';

export default {
  props: ['pageTitle'],
  components: {TitleBar},
  head: function() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `Information about Arctangent 2019`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    isEmpty(arg) {
      return isEmpty(arg);
    }
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';

a {
  color: $green;
}

.w-100 {
  width: 100%;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  padding: 0 20px;
}

.content {
    font-size: 16px;
    line-height: 1.5;

    p {
        margin: 0 0 12px 0;
        padding: 0 0 0 0;
    }

    h1,h2,h3 {
        padding: 0 0 0 0;
        margin: 1.5rem 0 12px 0;
    }
    .byline {
        margin-top: 1.5rem;
        padding: 10px;
        background: #FCEAA8;
    }
}


@media screen and (max-width: 420px) {
  .page-wrapper {
    max-width: 100%;
  }
}
</style>
