<template>
    <div class="title-bar">
        <h1>{{ title }} <span v-if="count">({{ count }})</span></h1>
    </div>
</template>

<script>
export default {
    props: ['title', 'count'],
    name: 'TitleBar'
}
</script>

<style lang="scss" scoped>
.title-bar {
    padding: 15px 20px 0 20px;

    h1,h2,h3 {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
    }
}
</style>

