<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
      <TabBar/>
    </div>

    <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
    <LocationFilterSheet/>
  </div>
</template>
<script>
import TabBar from '@/components/TabBar';
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import LocationFilterSheet from '@/components/LocationFilterSheet'
import { mapState, mapActions, mapGetters } from 'vuex'
import db from '@/db';
import axios from 'axios'
export default {
  components: { NewContentAvailableToastr, AppleAddToHomeScreenModal, TabBar, LocationFilterSheet },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  methods: mapActions('app', [
    'closeAddToHomeScreenModalForApple',
    'serviceWorkerSkipWaiting'
  ]),
  async mounted() {
    let { data, status } = await axios.get('https://arctangent2019.s3.eu-west-2.amazonaws.com/artists_final.json');
    this.$store.commit('artists/setArtists', data.artists);
    this.$store.commit('artists/setLocationsFilterList', data.locations);
    
    let storedUserFavourites = await db.schedule.toArray();
    this.$store.commit('user/SET_FAVOURITES', storedUserFavourites.map(item => item.name));
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
* {
  box-sizing: border-box;
}
.scroll-lock {
  overflow: hidden;
}
body {
  margin: 0;

  a {
    font-weight: 500;
    text-decoration: none;
  }

  #app {
    font-family: -apple-system, "Nunito", BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #2c3e50;

    .new-content-available-toastr {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .apple-add-to-home-screen-modal {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
      margin: auto;
      z-index: 1000;
    }

    .main-wrapper {
      margin-bottom: $tab-bar-height;
      .page-wrapper {
        width: 60%;
        margin: auto;

        @media screen and (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
